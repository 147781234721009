import React from "react";
import MKButton from "./MKButton";

export const NextSection = ({lang, thisId, nextId}) => {
    if (!nextId) {
        return <></>
    }
    return <>
        <MKButton className={"anchor-button"} variant="gradient" color="primary" size="large" style={{ marginTop: "40px" }} href={"#" + nextId}>
            {lang.getTranslation(nextId, "step")}
        </MKButton>
    </>

}
