import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MKBox from "components/MKBox";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import { NextSection } from "../../components/next-section";

function Features({ lang, thisId, nextId }) {
  return (
    <>
      <div className="section-title">
        <h2>{lang.getTranslation(thisId, "title")}</h2>
      </div>
      <MKBox id={thisId} className="anchor-target" component="section" py={{ xs: 6, md: 12 }}>
        <Container>
          <Grid container item xs={12} justifyContent="center" className={"section"}>
            {
              lang.getTranslation(thisId, "items").map((d, i) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
                >
                  <Stack spacing={{ xs: 4, md: 8 }} style={{ minHeight: "100px", marginBottom: "32px" }}>
                    <SimpleInfoCard
                      icon="payment"
                      title={d.title}
                      description={d.text}
                    />
                  </Stack>
                </Grid>
              ))
            }
          </Grid>
          <Grid container item justifyContent="center" mx="auto"
                className={"next-section-grid"}>
            <NextSection lang={lang} thisId={thisId} nextId={nextId} />
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Features;
