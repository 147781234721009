import React, { useEffect, useState } from "react";
import logo from "../../assets/images/codex/logo-t-nt-c-h70.svg";
import styled from "styled-components";

const NavigationContainer = styled.div`
    padding-top: 32px;
    padding-bottom: 12px;
    padding-left: 12px;
    position: sticky;
    top: 0;
    z-index: 100;

    &.show {
        transition: opacity 0.5s;
        opacity: 1;
        filter: alpha(opacity=100);
    }

    &.hide {
        opacity: 0;
        pointer-events: none;
        padding: 0;
        margin: 0;
        height: 0 !important;
    }

    &.navbar-default {
        background-color: #172326;
        border-color: rgba(231, 231, 231, 0);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    a.navbar-brand {
        font-size: 24px;
        font-weight: 700;
        color: #333;
        text-transform: uppercase;
    }

    .navbar-nav > li > a {
        text-transform: uppercase;
        color: #555;
        font-size: 15px;
        font-weight: 400;
        padding: 8px 2px;
        border-radius: 0;
        margin: 9px 20px 0;
        position: relative;

        &:after {
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 0;
            height: 2px;
            background: linear-gradient(to right, #2d4152 0%, #3f5b73 100%);
            content: '';
            transition: width 0.2s;
        }

        &:hover:after {
            width: 100%;
        }
    }

    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover,
    .navbar-nav > .active > a:focus {
        background-color: transparent;

        &:after,
        &:hover:after,
        &:focus:after {
            display: block !important;
            position: absolute !important;
            left: 0 !important;
            bottom: -1px !important;
            width: 100% !important;
            height: 2px !important;
            background: linear-gradient(to right, #2d4152 0%, #3f5b73 100%) !important;
            content: '' !important;
            transition: width 0.2s !important;
        }
    }

    .navbar-toggle {
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: #fff;
            border-color: #608dfd;
        }

        &:hover > .icon-bar {
            background-color: #608dfd;
        }
    }
`;

export const Navigation = ({ lang }) => {

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    let currentIsScrolled = null;
    const scrollThreshold = 30;
    const hysteresis = 10; // Adjust this value to control the buffer

    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > scrollThreshold + hysteresis && !currentIsScrolled) {
        setIsScrolled(true);
        document.body.classList.add('navigation-visible');
        currentIsScrolled = true;
      } else if (scrollY < scrollThreshold - hysteresis && currentIsScrolled) {
        setIsScrolled(false);
        document.body.classList.remove('navigation-visible');
        currentIsScrolled = false;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <NavigationContainer id="menu" className={"navbar-default " + (isScrolled ? " show " : " hide")}>
      <div className="container">
        <div className="navbar-header">
          <a className="navbar-brand page-scroll" href="#top"
             style={{
               backgroundImage: `url(${logo})`,
               minWidth: "189px",
               fontSize: "24px",
               color: "#888999",
               paddingTop: "30px",
               paddingLeft: "30px",
               backgroundRepeat: "no-repeat",
             }}>Codex Analytica
          </a>
        </div>
      </div>
    </NavigationContainer>
  );
};
