import { Helmet } from "react-helmet";
import { Header } from "./header";
import Features from "./features";
import { Navigation } from "./navigation";
import Footer from "./footer";


function Home({ lang }) {

  return (
    <>
      <Helmet htmlAttributes={{ lang: lang.getLanguage() }}>
        <title>{lang.getTranslation("title")}</title>
        <meta charSet="UTF-8" />
        <meta httpEquiv="content-language" content="de-DE" />
        <meta name="viewport" content="width=600" />
        <meta name="description" content={lang.getTranslation("description")} />
      </Helmet>
      <Navigation thisId="header" nextId="how-it-works" lang={lang} />
      <Header thisId="header" nextId="features" lang={lang} />
      <Features thisId="features" nextId="how-it-works" lang={lang} />
      <Footer />
    </>
  );
}

export default Home;
