import TranslationsDE from "../data/de.json";
import TranslationsEN from "../data/en.json";

export class Language {

    constructor(languageCode) {
        this.languageCode = languageCode;
        this.translations = {};
        if (languageCode === "en") {
            this.translations = TranslationsEN
        } else {
            this.translations = TranslationsDE
        }
    }

    getTranslation(...keys) {
        let _trans = this.translations
        for (const key of keys) {
            if (key.trim() in _trans) {
                _trans = _trans[key.trim()];
            } else {
                return keys.toString()
            }
        }
        return _trans
    }

    getLanguage() {
        return this.languageCode
    }
}
