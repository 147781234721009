import MKBox from "../../components/MKBox";
import bgImage from "../../assets/images/codex/ca-hero-large2-2.webp";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import { NextSection } from "../../components/next-section";

export function Header({ lang, thisId, nextId }) {
  return (
    <>
      <MKBox
        id="page-top"
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} justifyContent="center">
              <MKTypography textAlign="center"
                            variant="h1"
                            color="white"
                            mt={-6}
                            mb={1}
                            sx={({ breakpoints, typography: { size } }) => ({
                              [breakpoints.down("md")]: {
                                fontSize: size["3xl"],
                              },
                            })}
              >
                {lang.getTranslation("header", "title")}
              </MKTypography>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <MKTypography variant="h4" color="white" textAlign="center" px={6} mt={1}>
                {lang.getTranslation("header", "subtitle")}
              </MKTypography>
            </Grid>
            <Grid item xs={12} justifyContent="center"  textAlign="center">
              <NextSection lang={lang} thisId={thisId} nextId={nextId} />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}
